import LoginRegister from '../components/LoginRegister/Main.vue'
export default [
    {
        path:'/',
        name:'HomePage',
        component: () => import('../components/HomePage/Main.vue')
    },
    {
        path:'/market',
        name:'Market',
        component: () => import('../components/PublicPages/Market.vue')
    },
    {
        path:'/fee',
        name:'Fee',
        component: () => import('../components/PublicPages/Fee.vue')
    },
    {
        path:'/privacyandpolicy',
        name:'PrivacyAndPolicy',
        component: () => import('../components/PublicPages/PrivacyAndPolicy.vue')
    },
    {
        path:'/aboutus',
        name:'AboutUs',
        component: () => import('../components/PublicPages/AboutUs.vue')
    },
    {
        path:'/faq',
        name:'Faq',
        component: () => import('../components/PublicPages/Faq.vue')
    },
    {
        path:'/contactus',
        name:'ContactUs',
        component: () => import('../components/PublicPages/ContactUs.vue')
    },
    {
        path:'/signup',
        name:'SignUp',
        component: LoginRegister,
    },
    {
        path:'/otpsignup',
        name:'OtpSignUp',
        component: LoginRegister,
    },
    {
        path:'/login',
        name:'Login',
        component: LoginRegister,
    },
    {
        path:'/otplogin',
        name:'OtpLogin',
        component: LoginRegister,
    },
    {
        path:'/forgetpass',
        name:'ForgetPass',
        component: LoginRegister,
    },
    {
        path:'/otpforgetpass',
        name:'OtpForgetPass',
        component: LoginRegister,
    },
    {
        path:'/newpass',
        name:'NewPass',
        component: LoginRegister,
    },
    {
        path:'/advancetrade/:tradeTo/:tradeFrom',
        name:'AdvanceTrade',
        props:true,
        component: () => import('../components/P2P/Main.vue')
    },
    {
        path: '/advancetrade',
        redirect: '/advancetrade/TOMAN/BITCOIN',
    },
    {
        path:'/dashboard',
        name:'Dashboard',
        component: () => import('../components/Panel/Main.vue'),
        children:[
            {
                path :'',
                name:'Dashboard',
                component: () => import('../components/Panel/Dashboard/Dashboard.vue'),
            },
            {
                path :'history',
                name:'History',
                component: () => import('../components/Panel/History/MainHistory.vue'),
            },
            {
                path :'notifications',
                name:'Notifications',
                component: () => import('../components/Panel/Notifications/Notifications.vue'),
            },
            {
                path :'invitefriends',
                name:'InviteFriends',
                component: () => import('../components/Panel/InviteFriends/InviteFriends.vue'),
            },
            {
                path :'settings',
                name:'Settings',
                component: () => import('../components/Panel/Setting/Settings.vue'),
            },
            {
                path :'wallet',
                name:'Wallet',
                component: () => import('../components/Panel/Wallet/Wallet.vue'),
            },
            {
                path :'deposit-coin',
                component: () => import('../components/Panel/Wallet/CoinDeposit.vue'),
            },
            {
                path :'rial-deposit',
                component: () => import('../components/Panel/Wallet/RialDeposit.vue'),
            },
            {
                path :'rial-withdrawal',
                component: () => import('../components/Panel/Wallet/RialWithdrawal.vue'),
            },
            {
                path :'withdrawal-coin',
                component: () => import('../components/Panel/Wallet/CoinWithdrawal.vue'),
            },
            {
                path :'account',
                name:'Account',
                component: () => import('../components/Panel/Account/Main.vue'),
            },
        ]
    },
    {
        path: '*',
        redirect: '/'
    }
]