import VueRouter from 'vue-router';
import routes from './Routes';
import Vue from 'vue';

// const that = Vue.prototype
Vue.use(VueRouter);


const router = new VueRouter({
  mode : 'history',
  base : process.env.BASE-URL,
  routes,
});

export default router
