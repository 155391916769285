<template>
    <div class="footer">
        <div class="footerlinks">
            <img class="logo" src="../../assets/Logo.svg" alt="">
            <div class="itemlinks">
                <div class="sitepages">
                    <div class="title">
                        <img src="../../assets/icons/FooterItem.svg" alt="">
                        <span class="linkstitle"> بخش های سایت </span>
                    </div>
                    <div class="links">
                        <a @click.prevent="reD('/')" href=""> صفحه اصلی </a>
                        <a @click.prevent="" href=""> بلاگ </a>
                        <a @click.prevent="reD('/signup')" href=""> ثبت نام </a>
                    </div>
                </div>
                <div class="sitepages">
                    <div class="title">
                        <img src="../../assets/icons/FooterItem.svg" alt="">
                        <span class="linkstitle"> راهنمای کاربران </span>
                    </div>
                    <div class="links">
                        <a @click.prevent="reD('/faq')" href=""> راهنمای استفاده </a>
                        <a @click.prevent="reD('/aboutus')" href=""> درباره ما </a>
                        <a @click.prevent="reD('/contactus')" href=""> تماس با ما </a>
                    </div>
                </div>
                <div class="sitepages">
                    <div class="title">
                        <img src="../../assets/icons/FooterItem.svg" alt="">
                        <span  class="linkstitle"> صرافی آنلاین </span>
                    </div>
                    <div class="links">
                        <a @click.prevent="reD('/market')" href=""> بازارها </a>
                        <a @click.prevent="reD('/fee')" href=""> کارمزد و سطوح کاربری </a>
                        <a @click.prevent="reD('/privacyandpolicy')" href=""> قوانین و مقررات </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="contantus">
            <h3> تماس با ما </h3>
            <div class="phonenums">
                <span> {{info.number}} </span>
                <span>  </span>
            </div>
            <span> پاسخگویی هر روز ۸ تا ۲۱ </span>
            <span> {{info.email}} </span>
            <div class="icons">
                <img src="../../assets/icons/facebook.svg" alt="">
                <img src="../../assets/icons/whatsapp.svg" alt="">
                <img src="../../assets/icons/telegram.svg" alt="">
                <img src="../../assets/icons/instagram.svg" alt="">
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'Footer',
    data() {
        return {
            info:[]
        }
    },
    methods:{
        async contantUS(){
            const res = await this.$axios.get('/exchange-info',);
            this.info = res.baseDTO || {
                    address: '',
                    appAndroid: '',
                    facebook:'',
                    appIOS: '',
                    email: 'exchange@mail.com',
                    instagram: '',
                    linkedin: '',
                    number: '22234234 - 021 ',
                    telegram: '',
            }
        },
        reD(destination){
            this.$router.push(destination);
            window.scrollTo(0,0)
        }
    },
    mounted() {
        this.contantUS();
    },
}
</script>

<style lang="scss" scoped>
.itemlinks{
    display: flex;
    flex-direction: row;
    justify-content: start;
    column-gap: 40px;
    height: 100%;
    width: 75%;
}
.sitepages{
    row-gap: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .title{
        display: flex;
        flex-direction: row;
        gap: 10px;
    }
    .links{
        height: 100%;
        padding: 0 30px;
        display: flex;
        flex-direction: column;
        gap: 13px;
        a{
            color: var(--grey);
            font-size: clamp(12px,1vw,14px);
        }
        // justify-content: space-between;
    }
}
.footerlinks{
    width: 70%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    .logo{
        cursor: pointer;
        width: 80px;
        height: 80px;
    }
}
.footer{
    padding: 50px;
    background: url('../../assets/FooterBG.svg');
    // background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 330px;
}
.contantus{
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    align-items: flex-start;
    h3{
        font-size: clamp(12px,1vw,14px);
    }
    span{
        font-size: clamp(10px,1vw,12px);
        color: var(--dark-grey);
    }
    .icons{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        column-gap: 12px;
    }
}
@media only screen and(max-width:1100px){
.footer{
    padding: 10px;
    flex-direction: column;
    gap: 70px;
    height: 100%;
    align-items: center;
    .contantus{
        width: 30%;
    }
    .footerlinks{
        width: 100%;
        .itemlinks{
            justify-content: space-between;
        }
    }
}
}
@media only screen and(max-width:700px){
    .footerlinks{
        flex-direction: column;
        align-items: center;
        row-gap: 40px;
        .itemlinks{
            width: 100%;
        }
    }
}
@media only screen and(max-width:600px){
        .itemlinks{
            flex-direction: column;
            align-items: center;
            row-gap:30px ;
        }
}
</style>