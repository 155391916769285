import Vue from 'vue'
import App from './App.vue'
import './Styles/Main.scss'
import './Styles/Hooper.scss'
import './Styles/Font-Yekan.css'
import router from './router/index';
import PerfectScrollbar from '@nima2142/vue2-perfect-scrollbar'
import '@nima2142/vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import VuePersianDatetimePicker from 'vue-persian-datetime-picker';
import TrendChart from "vue-trend-chart";
import InlineSvg from 'vue-inline-svg';
import VueCookies from 'vue-cookies'
import VueSweetalert2 from 'vue-sweetalert2'
import VueApexCharts from 'vue-apexcharts'
import 'sweetalert2/dist/sweetalert2.min.css'
import './library/state';
import './library/axios';
import './library/globalConstants';
import './library/globalFunctions';


Vue.config.productionTip = false
Vue.use(VueCookies)
Vue.use(VueSweetalert2);
Vue.component('date-picker', VuePersianDatetimePicker);
Vue.use(TrendChart);
Vue.component('apexchart', VueApexCharts)
Vue.component('inline-svg', InlineSvg);
Vue.use(PerfectScrollbar,{
  options:{
      // swipeEasing: false,
      wheelPropagation: false,
  }
});

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')