<template>
    <div class="otp">
        <h3> تایید ایمیل </h3>
        <form @submit.prevent="submit" class="ci-container">
            <div class="title">
                <span>
                    کد ارسال شده به
                    {{$athenType[state.userInfo.towStepAuthenticationType]}}
                    {{otp.email}}
                    را وارد کنید
                </span>
                <a @click.prevent="$router.name=='OtpLogin' ? $router.push('/login') : $router.push('/signup')" href="" class="back"> استفاده از ایمیل دیگر </a>
            </div>
            <CodeInput
                :loading="false" 
                class="verif-code" 
                v-on:complete="submit" 
                @complete="submit"
            />
            <span @click="resendBtn" class="counter"> {{counter ? counter : 'ارسال مجدد کد'}} </span>
            <button :disabled="!otp" class="submit"> تایید </button>
        </form>
    </div>
</template>


<script>
import CodeInput from "vue-verification-code-input";
export default {
    name:'Otp',
    components:{
        CodeInput,
    },
    data(){
        return{
            counter:120,
            timeOut:null,
            otp:{
                    code: '',
                    email: this.state.userInfo.email || this.$cookies.get('username')
            }
        }
    },
    computed:{

    },
    methods:{
        resendBtn(){
                this.counter=120
        },
        countDownTimer() {
                if (this.counter > 0) {
                    this.timeOut = setTimeout(() => {
                        this.counter--
                        this.countDownTimer()
                    }, 1000)
                } else {
                    this.$error('زمان شما به اتمام رسید', '')
                }
            },
        submit(){
            if(this.$route.name=='OtpLogin'){
                this.$router.push({name:'Dashboard'});
            }
            else
            if(this.$route.name=='OtpForgetPass'){
                this.$router.push('/newpass');
            }
            else
            if(this.$route.name=='OtpSignUp'){
                this.$router.push('/login');
            }
        }
    },
    beforeDestroy() {
            clearTimeout(this.timeOut)
        },
        mounted (){
            if (this.$route.name !== 'Loginotpgoogle') {
                this.countDownTimer()
            }
        }
}
</script>


<style lang="scss" scoped>
.otp{
    z-index: 5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    h3{
        font-family: 'sepahbod';
        font-size: clamp(20px,3vw,24px);
    }
    .ci-container{
        display: flex;
        flex-direction: column;
        row-gap: 32px;
        padding: 32px 24px;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        background: white;
        .title{
            width: 100%;
            display: flex;
            flex-direction: column;
            row-gap: 8px;
            a{
                text-align: end;
                color: var(--primary-color);
                font-size: clamp(12px,1vw,14px);
            }
        }
    }
    .counter{
        align-self: start;
    }
}
</style>