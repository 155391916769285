<template>
    <div class="forms">
        <div :class="$route.name.includes('Otp') ||  $route.name.includes('Forget') ||  $route.name.includes('New')?'':'forms-container'">
            <Register v-if="$route.name=='SignUp'" />
            <Login v-if="$route.name=='Login'" />
        </div>
            <Otp v-if="$route.name=='OtpLogin' || $route.name=='OtpForgetPass'" />
            <ForgetPass v-if="$route.name=='ForgetPass'" />
            <NewPass v-if="$route.name=='NewPass'" />
        <img class="authbanner" v-if="$route.name=='SignUp' || $route.name=='Login'" src="../../assets/authbanner.svg" alt="">
    </div>
</template>
<script>

import Register from './Register.vue';
import Login from './Login.vue';
import Otp from './Otp.vue';
import ForgetPass from './Forgetpass.vue';
import NewPass from './NewPass.vue';


export default {
    name:'Forms',
    components:{
        Register,
        Login,
        Otp,
        ForgetPass,
        NewPass,
    }
}
</script>
<style lang="scss" scoped>
.forms-container{
    width: 30%;
    z-index: 4;
}
img{
    z-index: 4;
}
.forms{
    padding: 70px 100px;
    min-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    background: var(--home-background);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    &::before{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: url('../../assets/bubble/authbilbilk.svg');
    }
    
}

@media only screen and(max-width:1100px){
    .forms{
        padding: 20px 30px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 50px;
    }
    .authbanner{
        width: 100%;
    }
    .forms-container{
        width: 50%;
    }
}
@media only screen and(max-width:800px){
    .forms-container{
        width: 70%;
    }
}
@media only screen and(max-width:600px){
    .forms-container{
        width: 90%;
    }
}
</style>