<template>
    <div class="newpass">
        <div class="contetn-container">
            <h2> رمز عبور جدید </h2>
            <form @submit.prevent="send" class="newpass-container">
                <div class="input-container">
                    <div class="spans">
                        <span class="input-span"> رمز عبور جدید </span>
                    </div>
                    <label for="email">
                        <img src="../../assets/icons/inputarrow.svg" alt="">
                        <input v-model="data.password" :type="showpass ? 'text':'password'">
                        <img @click="showpass=true" v-if="!showpass" src="../../assets/icons/dontseepassword.svg" alt="">
                        <img @click="showpass=false" v-if="showpass" class="seepass" src="../../assets/icons/seepassword.svg" alt="">
                    </label>
                </div>
                <button :disabled="!data.password || !$validPass(data.password) || data.password.length < 8" class="submit"> تایید </button>
            </form>
        </div>
    </div>
</template>
<script>
export default {
    name:'NewPass',
    data() {
        return {
            showpass:false,
            data:{
                password:null
            },

        }
    },
    methods:{
        send(){
            this.$route.push('/dashboard')
        }
    }
}
</script>

<style lang="scss" scoped>
.newpass{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
    justify-self: center;
    z-index: 5;
}
.contetn-container{
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    width: 30%;
}
.newpass-container{
    row-gap: 32px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 24px;
    border-radius: 12px;
    background: white;
}
@media only screen and(max-width:1300px){
    .contetn-container{
        width: 50%;
    }
}
@media only screen and(max-width:1100px){
    .contetn-container{
        width: 70%;
    }
}
@media only screen and(max-width:900px){
    .contetn-container{
        width: 85%;
    }
}
@media only screen and(max-width:600px){
    .contetn-container{
        width: 100%;
    }
}
</style>