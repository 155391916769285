<template>
  <div id="app">
    <Navbar v-if="!state.token && $route.name !== 'AdvanceTrade'" />
      <router-view/>
    <Footer v-if="$route.name =='AboutUs' || $route.name =='Faq' || $route.name =='Fee' || $route.name =='Market' || $route.name =='PrivacyAndPolicy'" />
  </div>
</template>

<script>
import Navbar from './components/Public/Navbar.vue';
import Footer from './components/Public/Footer.vue';


export default {
  name: 'App',
  components:{
    Navbar,
    Footer
  },
  mounted() {
    console.log(this.state.token)
  },
}
</script>

<style>

</style>
